.career-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

/* Header Section */
.career-header h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #003366;
}

.career-header p {
    font-size: 1.2rem;
    color: #666;
    margin-top: 10px;
    line-height: 1.5;
}

/* Content Section */
.career-content p {
    font-size: 1.1rem;
    margin-top: 20px;
    line-height: 1.6;
}

/* Button */
.career-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.career-button:hover {
    background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .career-header h1 {
        font-size: 1.8rem;
    }

    .career-header p,
    .career-content p {
        font-size: 1rem;
    }

    .career-button {
        font-size: 0.9rem;
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .career-header h1 {
        font-size: 1.5rem;
    }

    .career-header p,
    .career-content p {
        font-size: 0.9rem;
    }

    .career-button {
        font-size: 0.85rem;
        padding: 8px 16px;
    }
}

  