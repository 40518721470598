/* Contact Page */
.contact {
  padding: 50px;
  background-color: var(--light-bg-color);
}

/* Contact Information */
.contact-info {
  margin-bottom: 40px;
}

/* Contact Form */
.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

/* Form Group */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

/* Input and Textarea */
input, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Success Message */
.success-msg {
  color: var(--primary-color);
  font-size: 18px;
  margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact {
      padding: 30px 20px;
  }

  .contact-form {
      max-width: 100%;
      padding: 10px;
  }

  .form-group label {
      font-size: 14px;
  }

  input, textarea {
      padding: 10px;
      font-size: 14px;
  }

  .success-msg {
      font-size: 16px;
  }
}

@media (max-width: 480px) {
  .contact {
      padding: 20px 10px;
  }

  .contact-form {
      padding: 10px;
  }

  .form-group label {
      font-size: 12px;
  }

  input, textarea {
      padding: 8px;
      font-size: 12px;
  }

  .success-msg {
      font-size: 14px;
  }
}
