.team {
  text-align: center;
  padding: 40px 15px;
  color: #333;
  background-color: #f5f8fb;
}

.team h1 {
  font-size: 28px; /* Adjusted for better mobile scaling */
  color: #003366;
  margin-bottom: 10px;
}

.team p {
  font-size: 16px;
  color: #666;
  max-width: 90%;
  margin: 0 auto 20px;
  line-height: 1.5;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  padding: 0 10px;
}

/* Individual Team Member */
.team-member {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  max-width: 300px; /* Slightly increased max width for better appearance */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Profile Image */
.team-avatar {
  width: 70px; /* Smaller size for mobile */
  height: 70px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
}

/* Name & Role */
.team-title {
  font-size: 16px;
  font-weight: bold;
  color: #003366;
  margin-top: 5px;
}

.team-description {
  font-size: 14px;
  color: #666;
  line-height: 1.4;
  max-width: 90%;
  margin: 0 auto;
}

/* Mobile-First Responsive Design */
@media (max-width: 599px) {  /* Small screens */
  .team-container {
    flex-direction: column;
    align-items: center;
  }

  .team-member {
    width: 100%; /* Full width on mobile */
    max-width: 280px;
  }

  .team-title {
    font-size: 18px;
  }

  .team-description {
    font-size: 14px;
  }
}

/* Tablet View */
@media (min-width: 600px) and (max-width: 899px) {
  .team-member {
    width: calc(50% - 15px); /* Two per row */
  }
}

/* Small Desktop */
@media (min-width: 900px) {
  .team-member {
    width: calc(33.33% - 15px); /* Three per row */
  }
}

/* Large Screens */
@media (min-width: 1200px) {
  .team-member {
    width: calc(25% - 15px); /* Four per row */
  }
}
.team h1 {
  font-size: 28px; /* Optimized for mobile */
  color: #003366;
  margin-bottom: 10px;
  text-align: center;
}

.team p {
  font-size: 16px; /* Readable for mobile */
  color: #666;
  max-width: 90%;
  margin: 0 auto 20px;
  line-height: 1.5;
  text-align: center; /* Ensures text is centered */
  padding: 0 15px; /* Adds padding for better mobile readability */
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .team h1 {
    font-size: 32px; /* Larger heading for tablets and above */
  }

  .team p {
    font-size: 18px; /* Slightly larger text for better readability */
    max-width: 75%; /* Centers text in a more structured way */
  }
}

@media (min-width: 1024px) {
  .team p {
    max-width: 60%; /* Restricts text width on large screens for better readability */
    font-size: 20px;
  }
}
