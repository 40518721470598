/* Hero Section */
.hero-section {
  background-color: var(--primary-color);
  color: white;
  padding: 1px 1px; /* Reduced padding for half-height banner */
  text-align: center;
  height: 250px;
  margin-top: -10px;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 2.5em;
  margin-bottom: 15px;
}

.hero-content p {
  font-size: 1.25em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.hero-content .btn-primary {
  background-color: var(--secondary-color);
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
}

.hero-content .btn-primary:hover {
  background-color: #f43c3c;
}

/* Sections - About Us and Mission */
.about-us,
.mission {
  padding: 10px 10px; /* Reduced padding for a more compact section */
  max-width: 800px;
  margin: 0 auto;
  background-color: var(--light-bg-color);
  margin-top: -80px;
}

.about-us h2,
.mission h2 {
  color: var(--primary-color);
  font-size: 2.5em;
  margin-bottom: 15px;
}

.about-us p,
.mission p {
  font-size: 1.125em;
  line-height: 1.8;
  margin-bottom: 15px;
}

/* Spacing between sections */
.about-us {
  margin-top: 10px;
}

.mission {
  margin-top: -30px;
  margin-bottom: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Hero Section */
  .hero-section {
    padding: 20px;
    height: auto;
  }

  .hero-content h1 {
    font-size: 2em;
  }

  .hero-content p {
    font-size: 1em;
  }

  .about-us h2,
  .mission h2 {
    font-size: 2em;
  }

  .about-us p,
  .mission p {
    font-size: 1em;
  }

  .hero-content .btn-primary {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  /* Hero Section */
  .hero-section {
    padding: 15px;
    height: auto;
  }

  .hero-content h1 {
    font-size: 1.5em;
  }

  .hero-content p {
    font-size: 0.9em;
  }

  .about-us h2,
  .mission h2 {
    font-size: 1.75em;
  }

  .about-us p,
  .mission p {
    font-size: 0.9em;
  }

  .hero-content .btn-primary {
    font-size: 0.8em;
    padding: 8px 14px;
  }
}
