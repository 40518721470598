.products {
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-item {
  width: 250px;
  text-align: center;
  background: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.product-item:hover {
  transform: scale(1.05);
}

.product-item img {
  width: 100%;
  height: 200px; /* Uniform height */
  object-fit: cover;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .products {
    padding: 30px;
  }

  .product-item {
    width: 45%; /* Two items per row on tablets */
  }
}

@media (max-width: 480px) {
  .product-item {
    width: 90%; /* Full width for mobile */
  }

  .product-item h3 {
    font-size: 18px;
  }

  .product-item p {
    font-size: 14px;
  }

  .product-item img {
    height: 180px; /* Slightly smaller for better fit */
  }
}

