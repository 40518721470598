/* Contact.css */
.contact {
    padding: 50px;
    background-color: var(--light-bg-color);
  }
  
  .contact-info {
    margin-bottom: 40px;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
  }
  
  input, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .success-msg {
    color: var(--primary-color);
    font-size: 18px;
    margin-top: 20px;
  }
  /* Service items */
.service-item {
  margin-bottom: 20px;
  text-align: center;
}

.service-item h3 {
  font-size: 1.8rem;
  margin: 10px 0;
}

/* Button for toggling description */
.expand-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.expand-button:hover {
  background-color: #45a049;
}

.expand-button:focus {
  outline: none;
}

/* For mobile view, we make the button bigger and more readable */
@media (max-width: 768px) {
  .expand-button {
    width: 100%;
    padding: 12px;
    font-size: 1.2em;
  }

  .service-item {
    text-align: left;
  }
}
