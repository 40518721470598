/* Contact Section */
.contact {
  padding: 40px 20px;  /* Adjust padding for smaller screens */
  background-color: var(--light-bg-color);
  text-align: center;  /* Centers text on small screens */
}

/* Contact Info */
.contact-info {
  margin-bottom: 30px;
  font-size: 16px;
}

/* Form Container */
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

/* Form Fields */
.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
}

/* Input Fields */
input, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

/* Success Message */
.success-msg {
  color: var(--primary-color);
  font-size: 18px;
  margin-top: 20px;
}

/* Responsive Image */
.team-avatar1 {
  width: 100%;  /* Makes image responsive */
  max-width: 520px;  /* Limits max width */
  height: auto;  /* Maintains aspect ratio */
  display: block;
  margin: 0 auto;  /* Centers image */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contact {
      padding: 30px 15px;
  }
  
  .contact-info {
      font-size: 14px;
  }

  .contact-form {
      width: 100%;
  }

  input, textarea {
      font-size: 14px;
  }

  .team-avatar1 {
      width: 90%; /* Smaller image on mobile */
      max-width: 400px;
  }
}

@media (max-width: 480px) {
  .contact {
      padding: 20px 10px;
  }
  
  .contact-info {
      font-size: 13px;
  }

  .team-avatar1 {
      width: 100%;
      max-width: 320px;
  }
}
