/* Privacy Container */
.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: sans-serif;
    line-height: 1.6;
  }
  
  .privacy-container h1,
  .privacy-container h2,
  .privacy-container h3 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .privacy-container h1 {
    font-size: 2em;
  }
  
  .privacy-container h2 {
    font-size: 1.5em;
  }
  
  .privacy-container h3 {
    font-size: 1.2em;
  }
  
  .privacy-container p {
    margin-bottom: 15px;
  }
  
  .privacy-container ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .privacy-container li {
    margin-bottom: 5px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .privacy-container {
      padding: 15px;
    }
  
    .privacy-container h1 {
      font-size: 1.8em;
    }
  
    .privacy-container h2 {
      font-size: 1.4em;
    }
  
    .privacy-container h3 {
      font-size: 1.1em;
    }
  
    .privacy-container p {
      font-size: 1em;
    }
  
    .privacy-container ul {
      margin-left: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .privacy-container {
      padding: 10px;
    }
  
    .privacy-container h1 {
      font-size: 1.6em;
    }
  
    .privacy-container h2 {
      font-size: 1.3em;
    }
  
    .privacy-container h3 {
      font-size: 1em;
    }
  
    .privacy-container p {
      font-size: 0.9em;
    }
  
    .privacy-container ul {
      margin-left: 10px;
    }
  }
  